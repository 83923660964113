<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Thêm mới vai trò</h6>
        </template>
        <b-row>
          <b-col>
            <b-form-group label="Tên vai trò">
              <b-form-input
                v-model="input.name"
                placeholder="Tên"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col> </b-col>
        </b-row>
      </b-card>
    </b-card-group>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Chọn quyền</h6>
        </template>
        <b-row>
          <b-col
            sm="3"
            v-for="(item, index) in options.permission"
            :key="index"
          >
            <b-form-group>
              <template #label>
                <b-form-checkbox
                  v-model="allSelected[index]"
                  size="lg"
                  :value="true"
                  :unchecked-value="false"
                  @change="toggleAll(index)"
                >
                  <b>{{ item.group === null ? "Nhóm khác" : item.group }}</b>
                </b-form-checkbox>
              </template>

              <template v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  size="lg"
                  id="flavors"
                  v-model="input.permissionId"
                  :aria-describedby="ariaDescribedby"
                  name="description"
                  class="ml-4"
                  aria-label="Individual flavours"
                  value-field="id"
                  text-field="description"
                  stacked
                >
                  <b-form-checkbox
                    size="lg"
                    v-for="(itemPermission, keyPermission) in item.data"
                    :key="keyPermission"
                    :value="itemPermission.id"
                    >{{ itemPermission.description }}</b-form-checkbox
                  >
                </b-form-checkbox-group>
              </template>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col style="text-align: center">
            <b-button type="submit" variant="primary" v-on:click="storeRole()"
              >Thêm mới
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
// import OptionPermission from "@/view/pages/admin/OptionPermission.vue";

const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  mixins: [Common],
  data() {
    return {
      allSelected: [],
      flavours: ["Orange", "Grape", "Apple", "Lime", "Very Berry"],
      input: {
        name: null,
        permissionId: [],
      },
      options: {
        permission: [],
      },
      view: {
        currentPage: 1,
        paginate: {
          limit: 25,
          page: 1,
          totalPage: 1,
          total: 0,
          skip: 0,
        },
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý vai trò và phân quyền", route: "role-permission" },
    ]);
  },
  methods: {
    toggleAll(key) {
      if (this.allSelected[key]) {
        const idList = this.options.permission[key].data.map((item) => item.id);
        this.input.permissionId = this.input.permissionId.concat(idList);
      } else {
        const idList = this.options.permission[key].data.map((item) => item.id);
        this.input.permissionId = this.input.permissionId.filter(
          (element) => !idList.includes(element)
        );
      }
    },
    listAllPermission() {
      this.$bus.$emit("show-loading", true);
      CmsRepository.getGroupPermission()
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            this.options.permission = response.data.data;
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
          this.$bus.$emit("show-loading", false);
        });
    },
    storeRole() {
      if (this.input.name == null || this.input.name === "") {
        this.notifyAlert("warn", "Name không được bỏ trống");
        return;
      }
      if (this.input.permissionId.length === 0) {
        this.notifyAlert("warn", "Quyền không được bỏ trống");
        return;
      }
      let params = {
        permission_id: this.input.permissionId,
        name: this.input.name,
      };
      this.$bus.$emit("show-loading", true);
      CmsRepository.createRole(params)
        .then((response) => {
          if (response.data.error_code) {
            alert(response.data.message);
          } else {
            this.notifyAlert("success", "Tạo mới thành công");
            return this.$router.push({ name: "role-permission" });
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
          this.$bus.$emit("show-loading", false);
        });
    },
  },
  components: {},
  created() {
    this.listAllPermission();
  },
  watch: {
    "view.currentPage"() {
      this.searchData();
    },
    "input.permissionId"() {
      // console.log(this.options.permission)
      Object.keys(this.options.permission).forEach((index) => {
        const permissionOption = this.options.permission[index].data.map(
          (item) => item.id
        );
        let inputPermission = Object.values(this.input.permissionId);
        const check = permissionOption.every((value) =>
          inputPermission.includes(value)
        );
        if (check) {
          this.allSelected[index] = true;
        } else {
          this.allSelected[index] = false;
        }
      });
    },
  },
};
</script>
